import React, {useEffect, useMemo, useState} from "react";
import {
	Text,
	View,
	Modal, ScrollView, Switch, Image, ActivityIndicator, Button
} from "react-native";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import API from "../../helpers/Axios";
import {TouchableOpacity} from "react-native-web";
import moment from 'moment';
import '../../styles/modal.css';
import EditStudioScreen from '../../screens/modals/EditStudioScreen';
import EditPaymentScreen from '../../screens/modals/EditPaymentScreen';
import EditPicturesScreen from "../modals/EditPicturesScreen";
import {toast} from "react-toastify";
import {Popover} from "react-tiny-popover";
import {useTheme} from "@react-navigation/native";
import TimePicker from "../../components/TimePicker";
import RoomsEmbedScreen from "./RoomsEmbedScreen";
import Responsive from "../../helpers/Reponsive";
import GLOBAL from "../../config/Global";

function StudiosScreen ({navigation, route, auth}) {
  const { isMobile } = Responsive();
  const {colors, dark} = useTheme();

	const [isLoading, setIsLoading] = useState(true);
	const [isDisableLoading, setIsDisableLoading] = useState(false);
	const [studios, setStudios] = useState([]);
	const [currentStudio, setCurrentStudio] = useState(null);
	const [menuDisplay, setMenuDisplay] = useState( false);
	const [isSetHoursDisplay, setIsSetHoursDisplay] = useState( []);
	const [isVisible, setIsVisible] = useState(false);
	const [isPaymentVisible, setIsPaymentVisible] = useState(false);
	const [isImageEditorVisible, setIsImageEditorVisible] = useState(false);
	const [isDisableItemVisible, setIsDisableItemVisible] = useState(false);
	const [isActive, setIsActive] = useState(null);
	const [isDeleteItemVisible, setIsDeleteItemVisible] = useState(false);
	const [isDeleteLoading, setIsDeleteLoading] = useState(false);
	const [isUpdateLoading, setIsUpdateLoading] = useState(false);
	const [isBusinessHoursVisible, setIsBusinessHoursVisible] = useState(false);
	const [startTime, setStartTime] = useState(null);
	const [endTime, setEndTime] = useState(null);
	const [startTimeValue, setStartTimeValue] = useState('1000');
	const [endTimeValue, setEndTimeValue] = useState('1900');
	const [currentStudioImages, setCurrentStudioImages] = useState([]);
  const [isCopied, setIsCopied] = useState(false);

	useEffect(() => {
		if(route.params && route.params?.shouldReload) {
			setIsLoading(true);
			fetchData();
		}
	}, [route]);

	const dateString = [
		'Sunday',
		'Monday',
		'Tuesday',
		'Wednesday',
		'Thursday',
		'Friday',
		'Saturday'
	];

	const hoursSampleData = [
		{
			id: 1,
			title: 'Sunday',
			enable: false,
			startTime: '07:00:00',
			endTime: '19:00:00'
		},
		{
			id: 2,
			title: 'Monday',
			enable: true,
			startTime: '07:00:00',
			endTime: '19:00:00'
		},
		{
			id: 3,
			title: 'Tuesday',
			enable: true,
			startTime: '07:00:00',
			endTime: '19:00:00'
		},
		{
			id: 4,
			title: 'Wednesday',
			enable: true,
			startTime: '07:00:00',
			endTime: '19:00:00'
		},
		{
			id: 5,
			title: 'Thursday',
			enable: true,
			startTime: '07:00:00',
			endTime: '19:00:00'
		},
		{
			id: 6,
			title: 'Friday',
			enable: true,
			startTime: '07:00:00',
			endTime: '19:00:00'
		},
		{
			id: 7,
			title: 'Saturday',
			enable: true,
			startTime: '07:00:00',
			endTime: '19:00:00'
		}
	];
	const [calendar, setCalendar] = useState(hoursSampleData);

	const [currentDate, setCurrentDate] = useState(calendar[0]);

	const [breaks, setBreaks] = useState([]);

	useEffect(() => {
		if(currentStudio) {
			setIsActive(currentStudio.active);
			setCurrentStudioImages(currentStudio.images)
			if(!currentStudio.hours.length) {
				setCalendar(hoursSampleData);
				console.log(hoursSampleData);
			} else {
				let hoursData = [];
				currentStudio.hours.forEach((item) => {
					let hours = {};
					hours.id = item.date_code;
					hours.title = dateString[item.date_code-1];
					hours.enable = item.enabled;
					hours.startTime = item.started_at;
					hours.endTime = item.ended_at;
					hoursData.push(hours);
				});
				setCalendar(hoursData);
			}
		}
	}, [currentStudio]);

	const deleteStudio = (id) => {
		if(isDeleteLoading) {
			return;
		}
		setIsDeleteLoading(true);
		API.post('owner/delete-studio', {
			id: id
		}).then(res => {
			setIsDeleteLoading(false);
			setIsDeleteItemVisible(false);
			let data = studios;
			data = data.filter(x => {
				return x.id !== id;
			});
			setStudios(data);
			if(data.length) {
				setCurrentStudio(data[0]);
			} else {
				navigation.navigate('NewStudioScreen')
			}
		}).catch (error => {
			console.log(error);
		});
	}

	const fetchData = () => {
		API.get('owner/settings')
			.then(res => {
				if(res.data.length) {
					setStudios(res.data);
					setCurrentStudio(res.data[0]);
					setIsLoading(false);
					if(res.data[0].hours.length) {
						let hoursData = [];
						res.data[0].hours.forEach((item) => {
							let hours = {};
							hours.id = item.date_code;
							hours.title = dateString[item.date_code-1];
							hours.enable = item.enabled;
							hours.startTime = item.started_at;
							hours.endTime = item.ended_at;
							hoursData.push(hours);
						});
						setCalendar(hoursData);
					}
				} else {
					navigation.navigate('NewStudioScreen')
				}
			}).catch (error => {
			console.log(error);
		});
	}

	useEffect(() => {
		fetchData();
	}, []);

	const [tabIndex, setTabIndex] = useState(0);

  const [isEnabled, setIsEnabled] = useState(false);
  const toggleSwitch = () => {
    API.post('owner/studio/address/toggle', {
      id: currentStudio.id,
      is_enabled: !isEnabled
    }).then(res => {

    }).catch (error => {
      console.log(error);
    });
    setIsEnabled(previousState => !previousState);
  }

  useEffect(() => {
    if(currentStudio) {
      setIsEnabled(Boolean(currentStudio.show_address));
    }
  }, [currentStudio]);

  return isLoading ? (<ActivityIndicator style={{flex: 1, justifyContent: 'center', alignItems: 'center'}} />) : (
		<View
			style={{
				flex: 1
			}}
		>
			<Modal onRequestClose={() => setIsDeleteItemVisible(false)} transparent visible={isDeleteItemVisible}>
				{currentStudio && (
					<View
						style={{
							backgroundColor: 'white',
							borderColor: '#eee',
							borderRadius: 8,
							borderWidth: 1,
							margin: 'auto',
							shadowColor: "#000",
							shadowOffset: {
								width: 0,
								height: 2,
							},
							shadowOpacity: 0.05,
							shadowRadius: 3.84,
							elevation: 5,
							padding: 24,
							maxWidth: 550
						}}
					>
						<Text
							style={{
								textAlign: 'center',
								fontSize: 24,
								fontWeight: '600',
								marginBottom: 24
							}}
						>Are you sue want to delete this studio</Text>
						<Text
							style={{
								color: '#595959',
								fontSize: 16,
								lineHeight: 22,
								textAlign: 'center'
							}}
						>Are you sure you want to permanently delete this studio? You can not under this action.</Text>
						<View
							style={{
								marginTop: 24,
								flexDirection: 'row',
								alignItems: 'center',
								justifyContent: 'space-between'
							}}
						>
							<TouchableOpacity
								onPress={() => {
									setMenuDisplay(false);
									setIsDeleteItemVisible(false);
									setIsDeleteLoading(false);
								}}
								style={{
									height: 48,
									borderWidth: 1,
									borderColor: '#E4E1E0',
									borderRadius: 8,
									alignItems: 'center',
									justifyContent: 'center',
									flex: 1,
									marginRight: 8
								}}
							>
								<Text
									style={{
										fontWeight: '600',
										fontSize: 16
									}}
								>Cancel</Text>
							</TouchableOpacity>
							<TouchableOpacity
								onPress={() => {
									deleteStudio(currentStudio.id);
								}}
								style={{
									height: 48,
									backgroundColor: '#E55A5A',
									borderRadius: 8,
									alignItems: 'center',
									justifyContent: 'center',
									flex: 1,
									marginLeft: 8,
									flexDirection: 'row'
								}}
							>
								<Text
									style={{
										fontWeight: '600',
										fontSize: 16,
										color: 'white',
									}}
								>Delete studio</Text>
								{isDeleteLoading && <ActivityIndicator color={'white'} style={{marginLeft: 8}}/>}
							</TouchableOpacity>
						</View>
					</View>
				)}
			</Modal>
			<Modal onRequestClose={() => setIsBusinessHoursVisible(false)} transparent visible={isBusinessHoursVisible}>
				{currentStudio && (
					<View
						style={{
							backgroundColor: 'white',
							borderColor: '#eee',
							borderRadius: 8,
							borderWidth: 1,
							margin: 'auto',
							shadowColor: "#000",
							shadowOffset: {
								width: 0,
								height: 2,
							},
							shadowOpacity: 0.05,
							shadowRadius: 3.84,
							elevation: 5,
							padding: 24,
							width: 480
						}}
					>
						<TouchableOpacity
							onPress={() => setIsBusinessHoursVisible(false)}
							style={{
								position: 'absolute',
								right: 16,
								top: 16
							}}
						>
							<svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M18.707 5.293a1 1 0 0 1 0 1.414l-12 12a1 1 0 0 1-1.414-1.414l12-12a1 1 0 0 1 1.414 0Z"
									fill="#4F4F4F"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M5.293 5.293a1 1 0 0 1 1.414 0l12 12a1 1 0 0 1-1.414 1.414l-12-12a1 1 0 0 1 0-1.414Z"
									fill="#4F4F4F"
								/>
							</svg>
						</TouchableOpacity>
						<Text
							style={{
								textAlign: 'center',
								fontSize: 24,
								fontWeight: '600',
								marginBottom: 24
							}}
						>Studio's Business Hours</Text>
						<Text
							style={{
								color: '#595959',
								fontSize: 16,
								lineHeight: 22,
								textAlign: 'center'
							}}
						>When can clients book with you?</Text>
						<View
							style={{
								marginTop: 32
							}}
						>
							{calendar.map((item, index) => (
								<View
									key={index}
									style={{
										flexDirection: 'row',
										alignItems: 'center',
										justifyContent: 'space-between',
										height: 48,
										borderBottomWidth: 1,
										borderColor: '#e4e1e0'
									}}
								>
									<View
										style={{
											flexDirection: 'row',
											alignItems: 'center',
											marginRight: 24
										}}
									>
										<Switch
											onValueChange={() => {
												let currentValue = calendar[calendar.findIndex(x => x.id === item.id)].enable;
												let newCalendar = calendar;
												newCalendar[calendar.findIndex(x => x.id === item.id)].enable = !currentValue;
												setCalendar(prev => ([...newCalendar]));
											}}
											trackColor={"#767577"}
											thumbColor={calendar[calendar.findIndex(x => x.id === item.id)].enable ? "#3dba71" : "white"}
											ios_backgroundColor="#3e3e3e"
											value={calendar[calendar.findIndex(x => x.id === item.id)].enable}
										/>
										<Text
											style={{
												marginLeft: 8,
												fontWeight: '500',
												fontSize: 16
											}}
										>{item.title}</Text>
									</View>
									{!calendar[calendar.findIndex(x => x.id === item.id)].enable && (
										<Text
											style={{
												fontSize: 16,
												fontWeight: '500'
											}}
										>Closed</Text>
									)}
									{!!calendar[calendar.findIndex(x => x.id === item.id)].enable && (
										<Popover
											isOpen={isSetHoursDisplay['indicator_' + item.id]}
											positions={['bottom', 'right']}
											align={'end'}
											containerStyle={{
												zIndex: 60000,
											}}
											containerClassName={'breaks-popover'}
											padding={10}
											reposition={true}
											//onClickOutside={() => setIsSetHoursDisplay(false)}
											content={({ position, nudgedLeft, nudgedTop }) => (
												<View
													style={{
														borderRadius: 8,
														width: 400,
														backgroundColor: 'white',
														padding: 16,
														shadowColor: "#000",
														shadowOffset: {
															width: 0,
															height: 2,
														},
														shadowOpacity: 0.25,
														shadowRadius: 3.84,
														elevation: 5,
													}}
												>
													<View
														style={{
															flexDirection: 'row',
															marginBottom: 24,
															alignItems: 'center'
														}}
													>
														<TouchableOpacity
															onPress={() => setIsSetHoursDisplay([])}
														>
														<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M19.071 12L4.92891 12" stroke="#6D6E6F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
															<path d="M11.2929 18.364L4.92894 12L11.2929 5.63604" stroke="#6D6E6F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
														</svg>
														</TouchableOpacity>
														<Text
															style={{
																marginLeft: 8,
																fontSize: 22,
																fontWeight: '600'
															}}
														>{currentDate.title}</Text>
													</View>
													<Text
														style={{
															marginBottom: 24,
															color: colors.mainSubtextColor
														}}
													>Set your business hours here.</Text>
													<Text
														style={{
															marginBottom: 8,
															fontSize: 16
														}}
													>Opening hours</Text>
													<TimePicker
														sameIsValid={false}
														minuteIncrement={60}
														use24Hours={false}
														startMoment={startTime ? startTime : moment().set({hour: (new Date(`1991-09-23 ${item.startTime}`)).getHours(),minute: (new Date(`1991-09-23 ${item.startTime}`)).getMinutes()})}
														endMoment={endTime ? endTime : moment().set({hour: (new Date(`1991-09-23 ${item.endTime}`)).getHours(),minute: (new Date(`1991-09-23 ${item.endTime}`)).getMinutes()})}
														onChange={(e) => {
															setStartTime(e.startTime)
															setEndTime(e.endTime);
															setStartTimeValue(e.startTimeValue);
															setEndTimeValue(e.endTimeValue);
														}}
													/>
													<Text
														style={{
															marginBottom: 8,
															fontSize: 16,
															marginTop: 16
														}}
													>Break</Text>
													{breaks.map((item, index) => (
														<View
															key={index}
															style={{
																marginTop: 8,
																flexDirection: 'row',
																justifyContent: 'space-between'
															}}
														>
															<TimePicker
																minValue={startTimeValue}
																maxValue={endTimeValue}
																sameIsValid={false}
																minuteIncrement={15}
																use24Hours={false}
																startMoment={moment(breaks[index].startTime)}
																endMoment={moment(breaks[index].endTime)}
																onStartTimeChange={(e) => {
																	let breakPoints = breaks;
																	breakPoints[index].startTime = e.startTime;
																	setBreaks(prev => ([...breakPoints]));
																}}
																onEndTimeChange={(e) => {
																	let breakPoints = breaks;
																	breakPoints[index].endTime = e.endTime;
																	setBreaks(prev => ([...breakPoints]));
																}}

															/>
															<TouchableOpacity
																style={{
																	marginTop: 8
																}}
																onPress={() => {
																	let breakPoints = breaks;
																	breakPoints.splice(index, 1);
																	setBreaks(prev => ([...breakPoints]));
																}}
															>
																<svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
																	<path
																		fillRule="evenodd"
																		clipRule="evenodd"
																		d="M18.707 5.293a1 1 0 0 1 0 1.414l-12 12a1 1 0 0 1-1.414-1.414l12-12a1 1 0 0 1 1.414 0Z"
																		fill="#4F4F4F"
																	/>
																	<path
																		fillRule="evenodd"
																		clipRule="evenodd"
																		d="M5.293 5.293a1 1 0 0 1 1.414 0l12 12a1 1 0 0 1-1.414 1.414l-12-12a1 1 0 0 1 0-1.414Z"
																		fill="#4F4F4F"
																	/>
																</svg>
															</TouchableOpacity>
														</View>
													))}
													<TouchableOpacity
														onPress={() => {
															let startBreakAt = startTime ? startTime : moment()
																.set({hour: (new Date(`1991-09-23 ${item.startTime}`))
																		.getHours(),minute: (new Date(`1991-09-23 ${item.startTime}`))
																		.getMinutes(), second: 0}
																		)
																.add('15', 'minutes')
																.toString();
															let endBreakAt = moment(startBreakAt).add('15', 'minutes').toString();

															let timeItem = {
																startTime: startBreakAt,
																endTime: endBreakAt
															}
															let breakPoints = breaks;
															breakPoints.push(timeItem);
															setBreaks(prev => ([...breakPoints]));
														}}
														style={{
															flexDirection: 'row',
															alignItems: 'center',
															marginTop: 32
														}}
													>
														<svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M12 8V16" stroke="#323232" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
															<path d="M16 12H8" stroke="#323232" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
															<path fillRule="evenodd" clipRule="evenodd" d="M12 21V21C7.029 21 3 16.971 3 12V12C3 7.029 7.029 3 12 3V3C16.971 3 21 7.029 21 12V12C21 16.971 16.971 21 12 21Z" stroke="#323232" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
														</svg>
														<Text
															style={{
																marginLeft: 8,
																fontSize: 16,
																fontWeight: '500'
															}}
														>Add Break</Text>
													</TouchableOpacity>
													<View
														style={{
															flexDirection: 'row',
															justifyContent: 'space-between',
															marginTop: 32
														}}
													>
														<TouchableOpacity
															onPress={() => {
																setIsSetHoursDisplay([]);
																setStartTime(null);
																setEndTime(null);
															}}
															style={{
																alignItems: 'center',
																justifyContent: 'center',
																borderWidth: 1,
																height: 48,
																borderRadius: 6,
																paddingHorizontal: 24,
																borderColor: '#e1e1e1'
															}}
														>
															<Text
																style={{
																	fontSize: 16,
																	fontWeight: '500'
																}}
															>Cancel</Text>
														</TouchableOpacity>
														<TouchableOpacity
															onPress={() => {
																let newCalendar = calendar;
																if(startTime) {
																	newCalendar[calendar.findIndex(x => x.id === item.id)].startTime = moment(startTime).format('HH:mm:ss');
																}
																if(endTime) {
																	newCalendar[calendar.findIndex(x => x.id === item.id)].endTime = moment(endTime).format('HH:mm:ss');
																}
																newCalendar[calendar.findIndex(x => x.id === item.id)].breaks = breaks;
																setCalendar(prev => ([...newCalendar]));
																setStartTime(null);
																setEndTime(null);
																setIsSetHoursDisplay([]);
																setBreaks([]);
															}}
															style={{
																alignItems: 'center',
																justifyContent: 'center',
																height: 48,
																borderRadius: 6,
																paddingHorizontal: 24,
																backgroundColor: 'black'
															}}
														>
															<Text
																style={{
																	fontSize: 16,
																	fontWeight: '500',
																	color: 'white'
																}}
															>Save</Text>
														</TouchableOpacity>
													</View>
												</View>
											)}
										>
											<TouchableOpacity
												onPress={() => {
													const update = {};
													update['indicator_' + item.id] = true;
													setCurrentDate(item);
													setIsSetHoursDisplay(update);
													setStartTimeValue(item.startTime.replace(/:/g, '').substring(0, item.startTime.length - 2));
													setEndTimeValue(item.endTime.replace(/:/g, '').substring(0, item.endTime.length - 2));
												}}
												style={{
													flexDirection: 'row',
													alignItems: 'center',
													justifyContent: 'space-between',
												}}
											>
												<Text
													style={{
														fontSize: 16,
														fontWeight: '500'
													}}
												>{moment(new Date(`1991-09-23 ${item.startTime}`)).format('hh:mm A')}</Text>
												<Text
													style={{
														marginHorizontal: 8,
														fontSize: 16
													}}
												>-</Text>
												<Text
													style={{
														fontSize: 16,
														fontWeight: '500'
													}}
												>{moment(new Date(`1991-09-23 ${item.endTime}`)).format('hh:mm A')}</Text>
												<svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M10 16L14 12L10 8" stroke="#323232" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
												</svg>
											</TouchableOpacity>
										</Popover>
									)}
								</View>
							))}
						</View>
						<View
							style={{
								marginTop: 24,
								flexDirection: 'row',
								alignItems: 'center',
								justifyContent: 'space-between'
							}}
						>
							<TouchableOpacity
								onPress={() => {
									console.log(calendar);
									let sendData = calendar;
									sendData.forEach((item, index) => {
										if(item.breaks !== undefined) {
											item.breaks.forEach((breakItem, breakIndex) => {
												sendData[index].breaks[breakIndex].startTime = moment(breakItem.startTime).format('HH:mm:ss');
												sendData[index].breaks[breakIndex].endTime = moment(breakItem.endTime).format('HH:mm:ss')
											});
										}
									});

									if(isUpdateLoading) {
										return
									}
									setIsUpdateLoading(true);
									API.post(`owner/update-studio-hours`, {
										id: currentStudio.id,
										data: JSON.stringify(sendData)
									})
										.then(res => {
											setIsUpdateLoading(false);
											setIsBusinessHoursVisible(false);
										}).catch (error => {
										console.log(error.response);
									});
								}}
								style={{
									height: 48,
									backgroundColor: '#3DBA71',
									borderRadius: 8,
									alignItems: 'center',
									justifyContent: 'center',
									flex: 1,
									marginLeft: 8,
									flexDirection: 'row'
								}}
							>
								<Text
									style={{
										fontWeight: '600',
										fontSize: 16,
										color: 'white',
									}}
								>Save</Text>
								{isUpdateLoading && <ActivityIndicator color={'white'} style={{marginLeft: 8}}/>}
							</TouchableOpacity>
						</View>
					</View>
				)}
			</Modal>
			<Modal onRequestClose={() => setIsDisableItemVisible(false)} transparent visible={isDisableItemVisible}>
				<View
					style={{
						backgroundColor: 'white',
						borderColor: '#eee',
						borderRadius: 8,
						borderWidth: 1,
						margin: 'auto',
						shadowColor: "#000",
						shadowOffset: {
							width: 0,
							height: 2,
						},
						shadowOpacity: 0.05,
						shadowRadius: 3.84,
						elevation: 5,
						padding: 24,
						maxWidth: 550
					}}
				>
					<Text
						style={{
							textAlign: 'center',
							fontSize: 24,
							fontWeight: '600',
							marginBottom: 24
						}}
					>Temporarily disable studio</Text>
					<Text
						style={{
							color: '#595959',
							fontSize: 16,
							lineHeight: 22,
							textAlign: 'center'
						}}
					>Are you sure you want to temporarily deactivate {currentStudio.name}? Users won’t be able to book sessions until the studio is activated again.</Text>
					<View
						style={{
							marginTop: 24,
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'space-between'
						}}
					>
						<TouchableOpacity
							onPress={() => {
								setMenuDisplay(false);
								setIsDisableItemVisible(false);
								setIsDisableLoading(false);
							}}
							style={{
								height: 48,
								borderWidth: 1,
								borderColor: '#E4E1E0',
								borderRadius: 8,
								alignItems: 'center',
								justifyContent: 'center',
								flex: 1,
								marginRight: 8
							}}
						>
							<Text
								style={{
									fontWeight: '600',
									fontSize: 16
								}}
							>Cancel</Text>
						</TouchableOpacity>
						<TouchableOpacity
							onPress={() => {
								if(isDisableLoading) {
									return;
								}
								setIsDisableLoading(true);
								API.post(`owner/disable-studio`, {
									id: currentStudio.id
								})
									.then(res => {
										setMenuDisplay(false);
										setIsDisableLoading(false);
										setIsDisableItemVisible(false);
										setIsActive(res.data.active);
										let newStudios = studios;
										let index = newStudios.findIndex(item => item.id === currentStudio.id);
										newStudios[index].active = res.data.active;
										setStudios(newStudios);
									}).catch (error => {
									console.log(error.response);
								});
							}}
							style={{
								height: 48,
								backgroundColor: '#E55A5A',
								borderRadius: 8,
								alignItems: 'center',
								justifyContent: 'center',
								flex: 1,
								marginLeft: 8,
								flexDirection: 'row'
							}}
						>
							<Text
								style={{
									fontWeight: '600',
									fontSize: 16,
									color: 'white',
								}}
							>Deactivate studio</Text>
							{isDisableLoading && <ActivityIndicator color={'white'} style={{marginLeft: 8}}/>}
						</TouchableOpacity>
					</View>
				</View>
			</Modal>
			<Modal onRequestClose={() => setIsVisible(false)} transparent visible={isVisible}>
				<View
					style={{
						backgroundColor: 'white',
						borderColor: '#eee',
						borderRadius: 8,
						borderWidth: 1,
						margin: 'auto',
						minWidth: 780,
						shadowColor: "#000",
						shadowOffset: {
							width: 0,
							height: 2,
						},
						shadowOpacity: 0.05,
						shadowRadius: 3.84,
						elevation: 5,
					}}
				>
					<EditStudioScreen
						setIsVisible={setIsVisible}
						item={currentStudio}
						setCurrentStudio={setCurrentStudio}
					/>
				</View>
			</Modal>
			<Modal onRequestClose={() => setIsPaymentVisible(false)} transparent visible={isPaymentVisible}>
				<View
					style={{
						backgroundColor: 'white',
						borderColor: '#eee',
						borderRadius: 8,
						borderWidth: 1,
						margin: 'auto',
						minWidth: 780,
						shadowColor: "#000",
						shadowOffset: {
							width: 0,
							height: 2,
						},
						shadowOpacity: 0.05,
						shadowRadius: 3.84,
						elevation: 5,
					}}
				>
					<EditPaymentScreen
						setIsVisible={setIsPaymentVisible}
					/>
				</View>
			</Modal>
			<Modal onRequestClose={() => setIsImageEditorVisible(false)} transparent visible={isImageEditorVisible}>
				<View
					style={{
						backgroundColor: 'white',
						borderColor: '#eee',
						borderRadius: 8,
						borderWidth: 1,
						margin: 'auto',
						minWidth: 780,
						shadowColor: "#000",
						shadowOffset: {
							width: 0,
							height: 2,
						},
						shadowOpacity: 0.05,
						shadowRadius: 3.84,
						elevation: 5,
					}}
				>
					<EditPicturesScreen
						item={currentStudio}
						currentImages={currentStudioImages}
						setIsVisible={setIsImageEditorVisible}
						type={'studio'}
						setCurrentImages={setCurrentStudioImages}
					/>
				</View>
			</Modal>
			<ScrollView
				style={{
					flex: 1,
					padding: isMobile ? 24 : 40
				}}
			>
				<Text
					style={{
						fontSize: 48,
						fontWeight: '600'
					}}
				>Manage Studios</Text>
				<Text
					style={{
						marginTop: 16,
						fontSize: 20,
						color: '#595959'
					}}
				>View, edit and update information for your studios.</Text>
				<ScrollView
					horizontal={true}
					style={{
						flexDirection: 'row',
						marginVertical: 40
					}}
				>
					<TouchableOpacity
						onPress={() => navigation.navigate('NewStudioScreen')}
						style={{
							backgroundColor: '#F4F2F1',
							justifyContent: 'center',
							alignItems: 'center',
							padding: 16,
							marginRight: 24,
							borderRadius: 8,
							borderWidth: 1,
							borderColor: '#D9D6D6',
							flexDirection: 'row',

						}}
					>
						<svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M21 14V8a5 5 0 0 0-5-5H8a5 5 0 0 0-5 5v8a5 5 0 0 0 5 5h6"
								stroke="#323232"
								strokeWidth={1.5}
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M21 14v2a5 5 0 0 1-5 5h-2"
								stroke="#323232"
								strokeWidth={1.5}
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M15 21v-1a5 5 0 0 1 5-5h1M10 12h4M12 14v-4"
								stroke="#323232"
								strokeWidth={1.5}
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
						<Text
							style={{
								marginLeft: 8,
								fontWeight: '600'
							}}
						>Add new studio</Text>
					</TouchableOpacity>

					{studios.map((item, index) => (
						<TouchableOpacity
							key={index}
							onPress={() => setCurrentStudio(item)}
							style={{
								width: 364,
								flexDirection: 'row',
								borderWidth: item.id === currentStudio.id ? 2 : 1,
								borderColor: item.id === currentStudio.id ? '#6EB28B' : '#E4E1E0',
								padding: 8,
								borderRadius: 8,
								backgroundColor: 'white',
								marginRight: 24,
								overflow: 'hidden'
							}}
						>
							{!item.approved && (
								<View
									style={{
										position: 'absolute',
										right: 0,
										top: 0,
										backgroundColor: '#E55A5A',
										paddingHorizontal: 8,
										paddingVertical: 4,
										borderBottomLeftRadius: 8
									}}
								>
									<Text
										style={{
											color: 'white',
											fontSize: 12,
										}}
									>Not approved</Text>
								</View>
							)}
							<View
								style={{
									width: 128,
									aspectRatio: 1.777,
									borderRadius: 4,
									backgroundColor: 'gray',
									marginRight: 16,
									overflow: 'hidden'
								}}
							>
								<Image
									style={{
										flex: 1
									}}
									source={{
										uri: item.artwork_url,
									}}
								/>
							</View>
							<View
								style={{
									flex: 1,
									justifyContent: 'center'
								}}
							>
								<Text
									style={{
										fontWeight: '600',
										color: '#096730',
										fontSize: 18
									}}
								>{item.name}</Text>
								<Text
									style={{
										fontWeight: '600',
										marginTop: 4,
										color: '#595959'
									}}
									numberOfLines={2}
								>{item.address}</Text>
							</View>
						</TouchableOpacity>
					))}
				</ScrollView>
				<View
					style={{
						backgroundColor: 'white',
						shadowColor: "#000",
						shadowOffset: {
							width: 0,
							height: 2,
						},
						shadowOpacity: 0.05,
						shadowRadius: 3.84,
						elevation: 5,
						borderRadius: 8,
						paddingHorizontal: 32,
						paddingVertical: 16
					}}
				>
					<View
						style={{
							flexDirection: 'row',
							gap: 16
						}}
					>
						<View
							style={{
								borderBottomColor: '#e4e1e0',
								borderBottomWidth: 2,
								position: 'absolute',
								bottom: 0,
								left: 0,
								right: 0
							}}
						></View>
						<TouchableOpacity
							onPress={() => setTabIndex(0)}
							style={{
								borderBottomColor: tabIndex === 0 ? '#6EB28B' : '#e4e1e0',
								borderBottomWidth: 2
							}}
						>
							<Text
								style={{
									fontWeight: '600',
									fontSize: 16,
									paddingVertical: 8
								}}
							>Studio settings</Text>
						</TouchableOpacity>
						<TouchableOpacity
							onPress={() => setTabIndex(1)}
							style={{
								borderBottomColor: tabIndex === 1 ? '#6EB28B' : '#e4e1e0',
								borderBottomWidth: 2
							}}
						>
							<Text
								style={{
									fontWeight: '600',
									fontSize: 16,
									paddingVertical: 8
								}}
							>Rooms</Text>
						</TouchableOpacity>
					</View>
					{tabIndex === 0 && (
						<>
							<View
								style={{
									marginTop: 32,
									flexDirection: 'row',
									alignItems: 'center',
									justifyContent: 'flex-end',
									zIndex: 1
								}}
							>
								<View
									style={{
										flexDirection: 'row',
										alignItems: 'center',
									}}
								>
									<View
										style={{
											marginHorizontal: 24,
											flexDirection: 'row',
											alignItems: 'center'
										}}
									>
										<Text
											style={{
												marginRight: 8,
												fontWeight: '600'
											}}
										>Studio link</Text>
										<Text
											style={{
												color: '#30529F',
												fontWeight: '600',
												marginRight: 8,
											}}
										>https://studio.stugo.com/{currentStudio.unique_id}</Text>
										<TouchableOpacity
											onPress={() => {
												navigator.clipboard.writeText(`https://studio.stugo.com/${currentStudio.unique_id}`)
												toast.success('Copied to clipboard')
											}}
										>
											<svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
												<rect
													x={17.002}
													y={17.002}
													width={14.006}
													height={14.006}
													rx={2}
													transform="rotate(-180 17.002 17.002)"
													stroke="#6D6E6F"
													strokeWidth={1.5}
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
												<path
													d="M17.002 6.997h2a2 2 0 0 1 2.001 2.001v10.005a2 2 0 0 1-2 2H8.998a2 2 0 0 1-2-2v-2.001"
													stroke="#6D6E6F"
													strokeWidth={1.5}
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
											</svg>
										</TouchableOpacity>
									</View>
									<Text
										style={{
											fontWeight: '600',
										}}
									>Studio status</Text>
									{isActive === 1 && (
										<View
											style={{
												backgroundColor: '#EDFDF4',
												paddingHorizontal: 12,
												height: 26,
												borderRadius: 13,
												justifyContent: 'center',
												marginLeft: 16
											}}
										>
											<Text
												style={{
													fontWeight: '600',
													color: '#096730'
												}}
											>Active</Text>
										</View>
									)}
									{isActive === 0 && (
										<View
											style={{
												backgroundColor: '#D2D2D2',
												paddingHorizontal: 12,
												height: 26,
												borderRadius: 13,
												justifyContent: 'center',
												marginLeft: 16
											}}
										>
											<Text
												style={{
													fontWeight: '600',
												}}
											>Deactivated</Text>
										</View>
									)}
									<View
										style={{
											display: 'inline-block'
										}}
									>
										<Popover
											isOpen={menuDisplay}
											positions={['bottom', 'right']}
											align={'end'}
											containerStyle={{
												zIndex: 5,
											}}
											padding={10} // adjust padding here!
											reposition={false} // prevents automatic readjustment of content position that keeps your popover content within its parent's bounds
											onClickOutside={() => setMenuDisplay(false)} // handle click events outside of the popover/target here!
											content={({ position, nudgedLeft, nudgedTop }) => ( // you can also provide a render function that injects some useful stuff!
												<View
													style={{
														borderRadius: 8,
														position: 'absolute',
														right: 0,
														width: 240,
														backgroundColor: 'white',
														padding: 8,
														shadowColor: "#000",
														shadowOffset: {
															width: 0,
															height: 2,
														},
														shadowOpacity: 0.25,
														shadowRadius: 3.84,
														elevation: 5,
													}}
												>
													<TouchableOpacity
														onPress={() => {
															setMenuDisplay(false);
															setIsVisible(true);
														}}
														style={{
															flexDirection: 'row',
															paddingHorizontal: 8,
															height: 34,
															borderRadius: 4,
															alignItems: 'center'
														}}
													>
														<svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
															<path
																d="M14 8.711v3.937c0 .747-.6 1.352-1.342 1.352H3.342A1.347 1.347 0 0 1 2 12.648v-8.63c0-.746.6-1.351 1.342-1.351H8"
																stroke="#323232"
																strokeLinecap="round"
																strokeLinejoin="round"
															/>
															<path
																d="m5.219 11.448 2.487-.622a.671.671 0 0 0 .31-.175l5.593-5.594c.521-.52.521-1.364 0-1.885l-.114-.115a1.333 1.333 0 0 0-1.886 0L6.016 8.651a.668.668 0 0 0-.175.31l-.622 2.487M10.553 4.113l2 2"
																stroke="#323232"
																strokeLinecap="round"
																strokeLinejoin="round"
															/>
															<path
																d="M7.697 10.828c.01-.07.022-.14.022-.214 0-.92-.746-1.667-1.667-1.667-.073 0-.143.013-.214.022"
																stroke="#323232"
																strokeLinecap="round"
																strokeLinejoin="round"
															/>
														</svg>
														<Text
															style={{
																marginLeft: 12,
																fontWeight: '600'
															}}
														>Edit studio details</Text>
													</TouchableOpacity>
													<TouchableOpacity
														onPress={() => {
															setMenuDisplay(false);
															setIsBusinessHoursVisible(true);
															setIsSetHoursDisplay([]);
															setStartTime(null);
															setEndTime(null);
														}}
														style={{
															flexDirection: 'row',
															paddingHorizontal: 8,
															height: 34,
															borderRadius: 4,
															alignItems: 'center',
															marginVertical: 16
														}}
													>
														<svg width={16} height={16} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M17.6919 18.377L20.0033 20.6884" stroke="#323232" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
															<path d="M6.30788 18.377L3.99667 20.6882" stroke="#323232" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
															<circle cx={12} cy="12.7504" r="8.00333" stroke="#323232" strokeWidth="1.5" />
															<path fillRule="evenodd" clipRule="evenodd" d="M6.4977 3.24609C7.79777 3.24864 8.9904 3.96807 9.599 5.11687C8.36719 5.49747 7.24741 6.17415 6.33765 7.08769C5.59886 7.83154 5.01113 8.71144 4.60692 9.67877C3.60201 9.04339 2.99377 7.93649 2.99625 6.74755C2.99837 4.81463 4.56479 3.24821 6.4977 3.24609Z" stroke="#323232" strokeWidth="1.5" />
															<path fillRule="evenodd" clipRule="evenodd" d="M17.5023 3.24609C19.4352 3.24821 21.0016 4.81463 21.0038 6.74755C21.0062 7.93649 20.398 9.04339 19.3931 9.67877C18.9889 8.71144 18.4011 7.83154 17.6624 7.08769C16.7526 6.17415 15.6328 5.49747 14.401 5.11687C15.0096 3.96807 16.2022 3.24864 17.5023 3.24609Z" stroke="#323232" strokeWidth="1.5" />
															<path d="M15.5995 13.2863H11.4644" stroke="#323232" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
															<path d="M11.4644 9.15137V13.2864" stroke="#323232" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
														</svg>
														<View
															style={{
																flex: 1,
																marginLeft: 12,
															}}
														>
															<Text
																style={{
																	fontWeight: '600'
																}}
															>Set Business Hours</Text>
															<Text
																style={{
																	marginTop: 4,
																	color: '#595959'
																}}
															>set your standard business hours.</Text>
														</View>
													</TouchableOpacity>
													{isActive === 1 && (
														<TouchableOpacity
															onPress={() => setIsDisableItemVisible(true)}
															style={{
																flexDirection: 'row',
																paddingHorizontal: 8,
																borderRadius: 4,
																alignItems: 'center',
																marginTop: 8
															}}
														>
															<svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
																<path
																	d="M8 12.667a4.63 4.63 0 0 1-1.67-.33M13.921 8.312C12.66 10.645 10.33 12.667 8 12.667M12.72 5.947c.46.54.87 1.128 1.201 1.742a.658.658 0 0 1 0 .623M3.333 12.667l9.334-9.334M6.515 9.485a2.1 2.1 0 0 1 2.97-2.97"
																	stroke="#323232"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																/>
																<path
																	d="M11.363 4.637C10.33 3.84 9.165 3.333 8 3.333c-2.33 0-4.66 2.022-5.921 4.356a.658.658 0 0 0 0 .623c.63 1.166 1.527 2.254 2.558 3.051"
																	stroke="#323232"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																/>
															</svg>
															<View
																style={{
																	flex: 1,
																	marginLeft: 12,
																}}
															>
																<Text
																	style={{
																		fontWeight: '600'
																	}}
																>Temporarily disable studio</Text>
																<Text
																	style={{
																		marginTop: 4,
																		color: '#595959'
																	}}
																>users won't be able to find your studio or its rooms on the app.</Text>
															</View>
														</TouchableOpacity>
													)}
													{isActive === 0 && (
														<TouchableOpacity
															onPress={() => {
																API.post(`owner/active-studio`, {
																	id: currentStudio.id
																})
																	.then(res => {
																		setMenuDisplay(false);
																		setIsActive(res.data.active);
																		let newStudios = studios;
																		let index = newStudios.findIndex(item => item.id === currentStudio.id);
																		newStudios[index].active = res.data.active;
																		setStudios(newStudios);
																	}).catch (error => {
																	console.log(error.response);
																});
															}}
															style={{
																flexDirection: 'row',
																paddingHorizontal: 8,
																borderRadius: 4,
																alignItems: 'center',
																marginTop: 8
															}}
														>
															<svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
																<path
																	clipRule="evenodd"
																	d="M3.118 12.467a.987.987 0 0 1 0-.935C5.01 8.033 8.505 5 12 5s6.99 3.033 8.882 6.533a.987.987 0 0 1 0 .935C18.99 15.967 15.495 19 12 19s-6.99-3.033-8.882-6.533Z"
																	stroke="#323232"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																/>
																<path
																	d="M14.121 9.879A3 3 0 1 1 9.88 14.12 3 3 0 0 1 14.12 9.88"
																	stroke="#323232"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																/>
															</svg>
															<View
																style={{
																	flex: 1,
																	marginLeft: 12,
																}}
															>
																<Text
																	style={{
																		fontWeight: '600'
																	}}
																>Active studio</Text>
																<Text
																	style={{
																		marginTop: 4,
																		color: '#595959'
																	}}
																>users will be able to find your studio or its rooms on the app.</Text>
															</View>
														</TouchableOpacity>
													)}
													<TouchableOpacity
														onPress={() => {
															setMenuDisplay(false);
															setIsDeleteItemVisible(true);
														}}
														style={{
															flexDirection: 'row',
															paddingHorizontal: 8,
															height: 34,
															borderRadius: 4,
															alignItems: 'center',
															marginTop: 8
														}}
													>
														<svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
															<path
																clipRule="evenodd"
																d="M10.362 14.002H5.638a1.5 1.5 0 0 1-1.496-1.385l-.644-8.369h9.004l-.644 8.369a1.5 1.5 0 0 1-1.496 1.385v0Z"
																stroke="#6D6E6F"
																strokeLinecap="round"
																strokeLinejoin="round"
															/>
															<path
																d="M13.336 4.248H2.665"
																stroke="#6D6E6F"
																strokeLinecap="round"
																strokeLinejoin="round"
															/>
															<path
																clipRule="evenodd"
																d="M6.124 1.998h3.752a.75.75 0 0 1 .75.75v1.5H5.374v-1.5a.75.75 0 0 1 .75-.75Z"
																stroke="#6D6E6F"
																strokeLinecap="round"
																strokeLinejoin="round"
															/>
															<path
																d="M9.313 7.25V11M6.687 7.25V11"
																stroke="#6D6E6F"
																strokeLinecap="round"
																strokeLinejoin="round"
															/>
														</svg>
														<Text
															style={{
																marginLeft: 12,
																fontWeight: '600'
															}}
														>Delete studio</Text>
													</TouchableOpacity>
												</View>
											)}
										>
											<TouchableOpacity
												onPress={() => setMenuDisplay(!menuDisplay)}
												style={{
													flexDirection: 'row',
													alignItems: 'center',
													marginLeft: 16,

												}}
											>
												<svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
													<path
														d="M18.503 12a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0M12.5 12a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0M6.498 12a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0"
														stroke="#323232"
														strokeWidth={1.5}
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
												</svg>
											</TouchableOpacity>
										</Popover>
									</View>
									{!currentStudio.approved && (
										<View
											style={{
												backgroundColor: '#E55A5A',
												paddingHorizontal: 12,
												height: 26,
												borderRadius: 13,
												justifyContent: 'center',
												marginLeft: 16
											}}
										>
											<Text
												style={{
													fontWeight: '600',
													color: 'white'
												}}
											>Not approved</Text>
										</View>
									)}
								</View>
							</View>
							<View
								style={{
									marginTop: 48,
									flexDirection: 'row',
									alignItems: 'center',
									justifyContent: 'space-between'
								}}
							>
								<Text
									style={{
										fontWeight: '600',
										fontSize: 18
									}}
								>Studio information</Text>

								<View
									style={{
										flexDirection: 'row'
									}}
								>
									<TouchableOpacity
										onPress={() => {
											setMenuDisplay(false);
											setIsPaymentVisible(true);
										}}
										style={{
											borderColor: '#E4E1E0',
											borderWidth: 1,
											paddingHorizontal: 12,
											height: 32,
											borderRadius: 6,
											alignItems: 'center',
											flexDirection: 'row',
										}}
									>
										<svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M14.003 6.332v-2c0-.922-.747-1.668-1.668-1.668h-8.67c-.92 0-1.667.746-1.667 1.667v2.335M14.003 9.667v2c0 .922-.747 1.668-1.668 1.668h-2.334"
												stroke="#6D6E6F"
												strokeWidth={1.2}
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
											<path
												clipRule="evenodd"
												d="M12.335 6.332h1.667c.368 0 .667.299.667.667v2a.667.667 0 0 1-.667.668h-1.667c-.921 0-1.668-.747-1.668-1.667v0c0-.921.747-1.668 1.668-1.668v0Z"
												stroke="#6D6E6F"
												strokeWidth={1.2}
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
											<path
												d="M8 10.667H1.33"
												stroke="#6D6E6F"
												strokeWidth={1.2}
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
											<path
												clipRule="evenodd"
												d="M6.666 8.666H2.664c-.736 0-1.333.598-1.333 1.334v2.668c0 .737.597 1.334 1.333 1.334h4.002c.737 0 1.334-.597 1.334-1.334V10c0-.736-.597-1.334-1.334-1.334Z"
												stroke="#6D6E6F"
												strokeWidth={1.2}
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
										<Text
											style={{
												fontWeight: '600',
												color: '#595959',
												marginLeft: 8
											}}
										>Payment/withdrawal methods</Text>
									</TouchableOpacity>
									<TouchableOpacity
										onPress={() => setIsVisible(true)}
										style={{
											marginLeft: 24,
											borderColor: '#E4E1E0',
											borderWidth: 1,
											paddingHorizontal: 12,
											height: 32,
											borderRadius: 6,
											alignItems: 'center',
											flexDirection: 'row',
										}}
									>
										<svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
											<g clipPath="url(#a)" fillRule="evenodd" clipRule="evenodd" fill="#595959">
												<path d="M2.667 3.333A.667.667 0 0 0 2 4v9.333a.666.666 0 0 0 .667.666H12a.666.666 0 0 0 .666-.666v-3.56a.667.667 0 0 1 1.334 0v3.56a2 2 0 0 1-2 2H2.666a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h3.56a.667.667 0 0 1 0 1.333h-3.56Z" />
												<path d="M11.528.861c.26-.26.683-.26.943 0l2.667 2.667c.26.26.26.682 0 .943L8.47 11.137a.667.667 0 0 1-.471.196H5.333a.667.667 0 0 1-.667-.667V7.999c0-.176.07-.346.196-.471L11.528.86ZM6 8.275V10h1.724l6-6L12 2.275l-6 6Z" />
											</g>
											<defs>
												<clipPath id="a">
													<path fill="#fff" d="M0 0h16v16H0z" />
												</clipPath>
											</defs>
										</svg>
										<Text
											style={{
												fontWeight: '600',
												color: '#595959',
												marginLeft: 8
											}}
										>Edit</Text>
									</TouchableOpacity>
								</View>
							</View>
							<View
								style={{
									flexDirection: 'row',
									marginTop: 32
								}}
							>
								<View
									style={{
										marginRight: 24
									}}
								>
									<Text
										style={{
											fontWeight: '600',
											marginBottom: 4
										}}
									>Name</Text>
									<Text>{currentStudio.name}</Text>
								</View>
								<View
									style={{
										marginRight: 24,
									}}
								>
									<Text
										style={{
											fontWeight: '600',
											marginBottom: 4
										}}
									>Business Incorporation</Text>
									<Text>{currentStudio.name}</Text>
								</View>
								<View
									style={{
										marginRight: 24,
									}}
								>
									<Text
										style={{
											fontWeight: '600',
											marginBottom: 4
										}}
									>Studio Type</Text>
									{currentStudio.category && (
										<Text>{currentStudio.category.name}</Text>
									)}
								</View>
								<View
									style={{
										marginRight: 24,
									}}
								>
									<Text
										style={{
											fontWeight: '600',
											marginBottom: 4
										}}
									>Account type</Text>
									<Text>Business</Text>
								</View>
								<View
									style={{
										marginRight: 24,
									}}
								>
									<Text
										style={{
											fontWeight: '600',
											marginBottom: 4
										}}
									>Tax ID</Text>
									<Text>0018190002-133</Text>
								</View>
								<View
									style={{
										marginRight: 24,
									}}
								>
									<Text
										style={{
											fontWeight: '600',
											marginBottom: 4
										}}
									>Added in</Text>
									<Text>{moment.utc(currentStudio.created_at).local().format("MMM D, YYYY")}</Text>
								</View>
							</View>
							<View
								style={{
									marginTop: 24,
									borderBottomWidth: 1,
									borderColor: '#E4E1E0',
									paddingBottom: 32
								}}
							>
								<Text
									style={{
										fontWeight: '600',
										marginBottom: 4
									}}
								>Address</Text>
								<Text
									style={{
										color: '#595959'
									}}
								>{currentStudio.address}</Text>
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginTop: 16,
                    gap: 16,
                  }}
                >
                  <Switch
                    trackColor={{false: '#767577', true: '#63ad88'}}
                    thumbColor={isEnabled ? '#f5dd4b' : '#f4f3f4'}
                    ios_backgroundColtoggleSwitchor="#008000"
                    onValueChange={toggleSwitch}
                    value={isEnabled}
                  />
                  <Text>Would you like to display the address on studio's profile?</Text>
                </View>
								<Text
									style={{
										marginTop: 24,
										fontWeight: '600',
										marginBottom: 4
									}}
								>Description</Text>
								<Text
									style={{
										color: '#595959'
									}}
								>{currentStudio.description}</Text>
							</View>
              <View
                style={{
                  marginTop: 32,
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Text
                  style={{
                    fontWeight: '600',
                    fontSize: 18
                  }}
                >Business hours</Text>
                <TouchableOpacity
                  onPress={() => {
                    setMenuDisplay(false);
                    setIsBusinessHoursVisible(true);
                    setIsSetHoursDisplay([]);
                    setStartTime(null);
                    setEndTime(null);
                  }}
                  style={{
                    borderColor: '#E4E1E0',
                    borderWidth: 1,
                    paddingHorizontal: 12,
                    height: 32,
                    borderRadius: 6,
                    alignItems: 'center',
                    flexDirection: 'row',
                  }}
                >
                  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#a)" fillRule="evenodd" clipRule="evenodd" fill="#595959">
                      <path d="M2.667 3.333A.667.667 0 0 0 2 4v9.333a.666.666 0 0 0 .667.666H12a.666.666 0 0 0 .666-.666v-3.56a.667.667 0 0 1 1.334 0v3.56a2 2 0 0 1-2 2H2.666a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h3.56a.667.667 0 0 1 0 1.333h-3.56Z" />
                      <path d="M11.528.861c.26-.26.683-.26.943 0l2.667 2.667c.26.26.26.682 0 .943L8.47 11.137a.667.667 0 0 1-.471.196H5.333a.667.667 0 0 1-.667-.667V7.999c0-.176.07-.346.196-.471L11.528.86ZM6 8.275V10h1.724l6-6L12 2.275l-6 6Z" />
                    </g>
                    <defs>
                      <clipPath id="a">
                        <path fill="#fff" d="M0 0h16v16H0z" />
                      </clipPath>
                    </defs>
                  </svg>
                  <Text
                    style={{
                      fontWeight: '600',
                      color: '#595959',
                      marginLeft: 8
                    }}
                  >Edit</Text>
                </TouchableOpacity>
              </View>
              <ScrollView
                horizontal
                style={{
                  marginTop: 24,
                  width: '100%'
                }}
              >
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: 40,
                  }}
                >
                  {calendar.map((item, index) => (
                    <View
                      key={index}
                      style={{
                        alignItems: 'center',
                      }}
                    >
                      <View
                        style={{
                          marginBottom: 8,
                        }}
                      >
                        <Text
                          style={{
                            fontWeight: '500',
                            fontSize: 14
                          }}
                        >{item.title}</Text>
                      </View>
                      {!calendar[calendar.findIndex(x => x.id === item.id)].enable && (
                        <Text
                          style={{
                            fontSize: 14,
                          }}
                        >Closed</Text>
                      )}
                      {!!calendar[calendar.findIndex(x => x.id === item.id)].enable && (
                        <View
                          style={{
                            flexDirection: 'row',
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 14,
                            }}
                          >{moment(new Date(`1991-09-23 ${item.startTime}`)).format('hA')}</Text>
                          <Text
                            style={{
                              marginHorizontal: 8,
                              fontSize: 14
                            }}
                          >-</Text>
                          <Text
                            style={{
                              fontSize: 14,
                            }}
                          >{moment(new Date(`1991-09-23 ${item.endTime}`)).format('hA')}</Text>
                        </View>
                      )}
                    </View>
                  ))}
                </View>
              </ScrollView>
              <View
                style={{
                  marginTop: 32,
                  height: 1,
                  borderBottomWidth: 1,
                  borderColor: '#E4E1E0',
                }}
              ></View>
							<View
								style={{
									marginTop: 32,
									flexDirection: 'row',
									alignItems: 'center',
									justifyContent: 'space-between',
								}}
							>
								<Text
									style={{
										fontWeight: '600',
										fontSize: 18
									}}
								>Pictures</Text>
								<TouchableOpacity
									onPress={() => setIsImageEditorVisible(true)}
									style={{
										borderColor: '#E4E1E0',
										borderWidth: 1,
										paddingHorizontal: 12,
										height: 32,
										borderRadius: 6,
										alignItems: 'center',
										flexDirection: 'row',
									}}
								>
									<svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
										<g clipPath="url(#a)" fillRule="evenodd" clipRule="evenodd" fill="#595959">
											<path d="M2.667 3.333A.667.667 0 0 0 2 4v9.333a.666.666 0 0 0 .667.666H12a.666.666 0 0 0 .666-.666v-3.56a.667.667 0 0 1 1.334 0v3.56a2 2 0 0 1-2 2H2.666a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h3.56a.667.667 0 0 1 0 1.333h-3.56Z" />
											<path d="M11.528.861c.26-.26.683-.26.943 0l2.667 2.667c.26.26.26.682 0 .943L8.47 11.137a.667.667 0 0 1-.471.196H5.333a.667.667 0 0 1-.667-.667V7.999c0-.176.07-.346.196-.471L11.528.86ZM6 8.275V10h1.724l6-6L12 2.275l-6 6Z" />
										</g>
										<defs>
											<clipPath id="a">
												<path fill="#fff" d="M0 0h16v16H0z" />
											</clipPath>
										</defs>
									</svg>
									<Text
										style={{
											fontWeight: '600',
											color: '#595959',
											marginLeft: 8
										}}
									>Edit</Text>
								</TouchableOpacity>
							</View>
							<ScrollView
								horizontal={true}
								style={{
									marginTop: 32,
									paddingBottom: 32
								}}
							>
								{currentStudioImages.map((item, index) => item.type === 'video' ? (
									<View
										key={index}
										style={{
											width: 280,
											aspectRatio: 1.777,
											borderRadius: 4,
											marginRight: 24,
											borderWidth: 1,
											borderColor: '#f1f0ef',
											overflow: 'hidden'
										}}
									>
										<video
											controls={true}
											style={{
												position: 'absolute',
												top: 0,
												left: 0,
												right: 0,
												bottom: 0,
												width: '100%'
											}}
											src={item['sm']} />
									</View>
								) : (
									<View
										key={index}
										style={{
											width: 280,
											aspectRatio: 1.777,
											borderRadius: 4,
											marginRight: 24,
											borderWidth: 1,
											borderColor: '#f1f0ef',
											overflow: 'hidden'
										}}
									>
										<Image
											style={{
												flex: 1
											}}
											source={{
												uri: item['sm'],
											}}
										/>
									</View>
								))}
							</ScrollView>
              <View
                style={{
                  marginTop: 32,
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  borderTopWidth: 1,
                  paddingTop: 32,
                  borderColor: '#e4e1e0'
                }}
              >
                <Text
                  style={{
                    fontWeight: '600',
                    fontSize: 18,
                    marginBottom: 4,
                  }}
                >Bookings</Text>
              </View>
              <Text>Copy the link to intergrate bookings with google calendar.</Text>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginTop: 16,
                }}
              >
                <View
                  style={{
                    backgroundColor: '#eeeeee',
                    flexDirection: 'row',
                    alignItems: 'center',
                    height: 32,
                    borderRadius: 16,
                    paddingHorizontal: 12
                  }}
                >
                  <Text>{GLOBAL.API_URL.replace('api', '') + 'bookings/calendar/' + btoa(unescape(encodeURIComponent(auth.user.id)))}</Text>
                </View>
                <TouchableOpacity
                  style={{
                    marginLeft: 16
                  }}
                  onPress={() => {
                    navigator.clipboard.writeText(GLOBAL.API_URL.replace('api', '') + 'bookings/calendar/' + btoa(unescape(encodeURIComponent(auth.user.id))))
                    toast.success('Copied to clipboard');
                    setIsCopied(true);
                  }}
                >
                  {isCopied ? (
                    <svg color="#07bc0c" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}><path d="M7 9.667A2.667 2.667 0 0 1 9.667 7h8.666A2.667 2.667 0 0 1 21 9.667v8.666A2.667 2.667 0 0 1 18.333 21H9.667A2.667 2.667 0 0 1 7 18.333z"></path><path d="M4.012 16.737A2 2 0 0 1 3 15V5c0-1.1.9-2 2-2h10c.75 0 1.158.385 1.5 1M11 14l2 2l4-4"></path></g></svg>
                  ) : (
                    <svg color="rgb(89, 89, 89)" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}><path d="M7 9.667A2.667 2.667 0 0 1 9.667 7h8.666A2.667 2.667 0 0 1 21 9.667v8.666A2.667 2.667 0 0 1 18.333 21H9.667A2.667 2.667 0 0 1 7 18.333z"></path><path d="M4.012 16.737A2 2 0 0 1 3 15V5c0-1.1.9-2 2-2h10c.75 0 1.158.385 1.5 1"></path></g></svg>
                  )}
                </TouchableOpacity>
              </View>
						</>
					)}
					{tabIndex === 1 && (
						<>
							<RoomsEmbedScreen navigation={navigation} route={route} studio={currentStudio} />
						</>
					)}
				</View>
			</ScrollView>
		</View>
	);
}

StudiosScreen.propTypes = {
	navigation: PropTypes.object,
};

export default connect(({auth}) => ({auth}))(StudiosScreen);
